import {
    Injectable,
    ComponentFactoryResolver,
    ApplicationRef,
    Injector,
    OnDestroy,
    TemplateRef,
    ViewContainerRef,
    EmbeddedViewRef,
    ComponentRef
} from '@angular/core';
import { ComponentPortal, ComponentType, TemplatePortal, DomPortalOutlet } from '@angular/cdk/portal';
import { IUIAttachedDOMItem } from 'types/dom-attachment';

@Injectable({ providedIn: 'root' })
export class UIDomAttachService implements OnDestroy {
    /**
     * Keep references for cleanup
     */
    private attachedItems: IUIAttachedDOMItem[] = [];

    // Inject the dependencies needed by the DOMPortalHost constructor
    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private appRef: ApplicationRef,
        private injector: Injector
    ) {}

    /**
     * Create and add component to DOM
     * @param componentType
     * @param outletElement What element to attach the component to. Defaults to `document.body`
     */
    addComponent<T>(
        componentType: ComponentType<T>,
        outletElement: Element = document.body
    ): IUIAttachedDOMComponent<T> {
        // Create a Portal based on the component
        const componentPortal = new ComponentPortal<T>(componentType);

        const domPortal = this.getDomPortal(outletElement);
        const componentRef = domPortal.attachComponentPortal(componentPortal);
        const item = {
            componentPortal: componentPortal,
            domPortalHost: domPortal,
            componentRef: componentRef,
            instance: componentRef.instance
        };

        this.attachedItems.push(item);

        return item;
    }

    /**
     * Add a template to DOM
     * @param templateRef
     * @param outletElement
     */
    addTemplate(
        templateRef: TemplateRef<any>,
        outletElement: Element,
        viewContainerRef: ViewContainerRef
    ): IUIAttachedDOMTemplate {
        // Create a Portal based on the component
        const templatePortal = new TemplatePortal(templateRef, viewContainerRef);

        const domPortal = this.getDomPortal(outletElement);

        const embeddedViewRef = domPortal.attachTemplatePortal(templatePortal);

        const item = {
            domPortalHost: domPortal,
            embeddedViewRef: embeddedViewRef
        };

        this.attachedItems.push(item);

        return item;
    }

    /**
     * Attach a template or component to DOM
     * @param portal
     * @param outletElement
     */
    private getDomPortal(outletElement: Element): DomPortalOutlet {
        // Create a PortalHost with outletElement as its anchor element
        const domPortalHost = new DomPortalOutlet(
            outletElement,
            this.componentFactoryResolver,
            this.appRef,
            this.injector
        );

        return domPortalHost;
    }

    /**
     * Remove a specific component
     * @param domPortalHost
     */
    remove(item?: IUIAttachedDOMTemplate | IUIAttachedDOMComponent<any>): void {
        if (item) {
            if (this.isDOMComponent(item) && item.componentPortal.isAttached) {
                item.componentPortal.detach();
            }

            item.domPortalHost.detach();
            // item.domPortalHost.dispose();

            const index = this.attachedItems.indexOf(item);
            if (index > -1) {
                this.attachedItems.splice(index, 1);
            }
        }
    }

    /**
     * Remove all components added by this service from
     */
    clear(): void {
        while (this.attachedItems.length) {
            this.remove(this.attachedItems.pop()); // Use pop to prevent eternal loop on error.
        }
    }

    /**
     * Cleanup
     */
    ngOnDestroy(): void {
        this.clear();
    }

    private isDOMComponent(item: IUIAttachedDOMItem): item is IUIAttachedDOMComponent<any> {
        return !!(item as IUIAttachedDOMComponent<any>).componentPortal;
    }
}

export interface IUIAttachedDOMItemBase {
    domPortalHost: DomPortalOutlet;
}

export interface IUIAttachedDOMComponent<T> extends IUIAttachedDOMItemBase {
    instance: T;
    componentPortal: ComponentPortal<T>;
    componentRef: ComponentRef<T>;
}

export interface IUIAttachedDOMTemplate extends IUIAttachedDOMItemBase {
    embeddedViewRef: EmbeddedViewRef<any>;
}
