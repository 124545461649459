import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChild,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    QueryList,
    Renderer2,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormControl } from '@angular/forms';
import { UITheme } from '../../../types/theme';
import { UISelectableBaseDirective } from '../../inputs/selectable-list';
import { UISelectableListComponent } from '../../inputs/selectable-list/selectable-list.component';
import { UIPopoverTargetDirective } from '../../popovers/popover/popover-target.directive';
import { UIPopoverDirective } from '../../popovers/popover/popover.directive';
import { UISelectLabelDirective } from './select.directive';
import { UISelectService } from './select.service';
import { UISelectTokenFieldTemplateDirective } from './templates/select-token-field-template.directive';
import { UIOptionComponent } from './option.component';
import { UIPopoverRef } from '../../popovers/popover/popover-ref';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'ui-select',
    templateUrl: 'select.component.html',
    providers: [UISelectService],
    styleUrls: ['select.component.scss'],
    host: {
        class: 'ui-select',
        '[class.disabled]': 'disabled',
        '[class.discrete]': 'discrete',
        '[class.open]': 'popoverOpen'
    }
})
export class UISelectComponent extends UISelectableListComponent implements OnChanges {
    @ViewChild('popover') popover: UIPopoverDirective;
    @ViewChild('target') target: UIPopoverTargetDirective;
    @ViewChild('text') textElement: ElementRef;
    @ViewChild('input') input: ElementRef;
    @ViewChild('selectButton') selectButton: ElementRef;
    @ContentChild(UISelectLabelDirective, { static: true }) customLabel?: UISelectLabelDirective;
    @ContentChild(UISelectTokenFieldTemplateDirective, { read: TemplateRef })
    customTokenField?: TemplateRef<any>;
    @Input() placeholder = 'No Item Selected';
    @Input() placeholderIcon: string;
    @Input() discrete = false;
    @Input() theme?: UITheme;
    @Input() useTargetWidth = false;
    @Input() validation?: UntypedFormControl;
    @Input() displaySelectedLimit = 3;
    @Input() backdropCss = '';
    @Input() maxHeight = 200;
    @Input() listMinWidth = 0;
    @Input() width? = 'auto';

    /**
     * @summary:
     * Show 'Select All' in the options.
     * @description:
     * By default 'Select All' is not shown but in some cases there is a need to show it.
     */
    @Input() showSelectAll = false;
    @Input() searchPlaceholder = 'Type to search...';
    @Output() onOpen = new EventEmitter<void>();
    @Output() onSearch = new EventEmitter<string>();

    popoverOpen = false;
    public override selectables: QueryList<UISelectableBaseDirective> =
        new QueryList<UISelectableBaseDirective>();
    public filteredItems: UISelectableBaseDirective[] = [];
    private currentPopoverRef: UIPopoverRef;

    constructor(
        protected override host: ElementRef,
        protected override renderer: Renderer2,
        protected override changeDetectorRef: ChangeDetectorRef,
        private uiSelectService: UISelectService
    ) {
        super(host, renderer, changeDetectorRef);
        this.uiSelectService.open$.pipe(takeUntilDestroyed()).subscribe(() => {
            this.openSelectPopover();
        });

        this.uiSelectService.close$
            .pipe(takeUntilDestroyed())
            .subscribe(() => this.onCloseSelectPopover());

        this.selectables.changes.pipe(takeUntilDestroyed()).subscribe(() => {
            this.initialize();
            this.changeDetectorRef.detectChanges();
        });

        this.selectedChange.pipe(takeUntilDestroyed()).subscribe(() => {
            if (this.currentPopoverRef && this.popoverOpen) {
                setTimeout(() => {
                    this.currentPopoverRef.overlayRef.updatePosition();
                });
            }
        });
    }

    public ngOnChanges(): void {
        if (this.theme) {
            this.host.nativeElement.setAttribute('ui-theme', this.theme);
            if (this.popover?.config) {
                this.popover.config.theme = this.theme;
            }
        }
        this.uiSelectService.isMultiSelect = this.multiSelect;
        this.uiSelectService.isTokenField = this.tokenField;

        this.changeDetectorRef.detectChanges();
    }

    close(): void {
        this.uiSelectService.close();
    }

    private onCloseSelectPopover(): void {
        this.changeDetectorRef.detectChanges();
        this.popover.close();
    }

    open(): void {
        this.uiSelectService.open();
    }

    private openSelectPopover(): void {
        if (this.disabled) {
            return;
        }
        this.popoverOpen = true;
        this.currentPopoverRef = this.popover.open(this.target);

        if (!this.searchable) {
            const option: (UISelectableBaseDirective | undefined)[] = [];

            if (Array.isArray(this.selected)) {
                this.selected.forEach(item => {
                    option?.push(this.getComponentByValue(item));
                });
            } else {
                option?.push(this.getComponentByValue(this.selected));
            }
            if (option) {
                setTimeout(() => {
                    option[0]?.host.nativeElement.scrollIntoView();
                });
            }
        } else {
            // TODO: would be better if we had popover.onDisplay() etc. to know when everything is rendered
            setTimeout(() => {
                this.changeDetectorRef.detectChanges();
                this.input?.nativeElement?.focus();
            });
        }

        setTimeout(() => {
            if (this.currentPopoverRef.overlayRef.backdropElement) {
                for (const css of this.backdropCss.split(' ')) {
                    if (css) {
                        this.currentPopoverRef.overlayRef.backdropElement.classList.add(css);
                    }
                }
            }
        });

        this.onOpen.next();
    }

    onKeyDown(event: KeyboardEvent): void {
        if (!this.popoverOpen) {
            return;
        }
        event.stopPropagation();

        const selectables = this.filteredItems.length ? this.filteredItems : this.selectables.toArray();
        const filteredSelectables = selectables.filter(
            item => item.host.nativeElement.style.display !== 'none' && !item.disabled
        );

        let index = filteredSelectables.findIndex(selectable => selectable.previewed);

        const selectedIndex = this.multiSelect
            ? index
            : filteredSelectables.findIndex(selectable => selectable.selected);

        // if no item is previewed, start from the current selection if not multiselect
        index = index === -1 ? selectedIndex : index;

        const previewedItem: UISelectableBaseDirective | undefined = filteredSelectables[index];

        switch (event.key) {
            case 'ArrowDown':
                this.onArrowUpOrDownPreview(event, previewedItem, filteredSelectables, index);
                break;
            case 'ArrowUp':
                {
                    const shouldFocusSearch = index === 0 && this.searchable;
                    if (shouldFocusSearch && previewedItem) {
                        previewedItem.setPreview(false);
                        this.selectButton.nativeElement.blur();
                        this.input.nativeElement.focus();
                        return;
                    }

                    this.onArrowUpOrDownPreview(event, previewedItem, filteredSelectables, index);
                }
                break;
            case 'Enter':
                this.onEnterPreview(event, previewedItem);
                break;
            case 'Escape':
                this.close();
                break;
            default:
                break;
        }
    }

    private onArrowUpOrDownPreview(
        event: KeyboardEvent,
        previewedItem: UISelectableBaseDirective | undefined,
        selectables: UISelectableBaseDirective[],
        index: number
    ): void {
        const isArrowDown = event.key === 'ArrowDown';
        const isArrowUp = event.key === 'ArrowUp';

        if (isArrowDown || isArrowUp) {
            event.preventDefault();

            const isFirstOptionPreviewed = isArrowUp && index === 0;
            const isLastOptionPreviewed = isArrowDown && index === selectables.length - 1;
            if (isFirstOptionPreviewed || isLastOptionPreviewed) {
                return;
            }

            let nextIndex;
            if (index === -1) {
                nextIndex = 0;
            } else if (isArrowDown) {
                nextIndex = index + 1;
            } else {
                nextIndex = index - 1;
            }

            if (previewedItem) {
                previewedItem.setPreview(false);
            }

            const newPreviewedItem = selectables[nextIndex];
            newPreviewedItem.setPreview(true);

            this.scrollIntoView(newPreviewedItem.host.nativeElement);
            this.previewChange.emit(newPreviewedItem.getValue());
        }
    }

    private onEnterPreview(
        event: KeyboardEvent,
        previewedItem: UISelectableBaseDirective | undefined
    ): void {
        if (!previewedItem) {
            return;
        }

        event.preventDefault();
        previewedItem.toggleSelect();

        if (this.tokenField) {
            previewedItem.setPreview(false);
        }

        if (!this.multiSelect) {
            this.close();
        }
    }

    private scrollIntoView(element: HTMLElement): void {
        const scrollableParentElement =
            this.popover.popoverTemplate.elementRef.nativeElement.parentElement;
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (!entry.isIntersecting) {
                    element.scrollIntoView({ block: 'nearest', inline: 'nearest' });
                }
                observer.disconnect();
            },
            {
                root: scrollableParentElement,
                threshold: 1.0
            }
        );

        observer.observe(element);
    }

    public onKeyDownSearch(event: KeyboardEvent): void {
        if (event.key === 'ArrowDown') {
            event.preventDefault();
            this.input.nativeElement.blur();
            this.selectButton.nativeElement.focus();
            this.onKeyDown(event);
        }
    }

    public onKeyUp(): void {
        this.changeDetectorRef.detectChanges();
        const inputValue: string = this.input.nativeElement.value;
        const inputValueLowercase = inputValue.toLowerCase();

        this.onSearch.emit(inputValue);

        if (inputValueLowercase) {
            this.clearPreview();

            this.filteredItems = this.selectables?.filter(item => {
                const textContent = item.host.nativeElement.innerText.toLowerCase();
                const isOptionComponent = item instanceof UIOptionComponent;
                const isAlwaysVisible = isOptionComponent && (item as UIOptionComponent).alwaysVisible;
                return isAlwaysVisible || textContent.includes(inputValueLowercase);
            });

            this.filteredItems.forEach(item => {
                item.host.nativeElement.style.display = 'flex';
            });

            const itemsNotFound = this.selectables?.filter(item => !this.filteredItems.includes(item));

            itemsNotFound.forEach(item => {
                item.host.nativeElement.style.display = 'none';
            });
        } else {
            this.resetSearch();
        }
    }

    public resetSearch(): void {
        this.filteredItems = [];
        this.selectables?.forEach(item => {
            item.host.nativeElement.style.display = 'flex';
        });
    }

    public hideItem(value: any): void {
        this.selectables?.forEach(item => {
            if (item.value === value) {
                item.host.nativeElement.style.display = 'none';
            }
        });
    }

    public selectAllList(): void {
        if (this.value && this.value.length === this.selectablesLength) {
            this.deselectAll();
        } else {
            this.selectAll();
        }
        this.close();
    }

    getIconByType(value: any, type: string): string | undefined {
        const component = this.getComponentByValue(value);

        if (!component) {
            return undefined;
        }

        if (type.toLowerCase() === 'svgicon') {
            return component ? component.svgIcon : '';
        } else if (type.toLowerCase() === 'icon') {
            return component ? component.icon : '';
        } else if (type.toLowerCase() === 'flag') {
            return component ? component.flag : '';
        } else if (type.toLowerCase() === 'image') {
            return component ? component.image : '';
        }

        return undefined;
    }

    getItemTextByValue(value: any): string {
        const component = this.getComponentByValue(value);
        if (component) {
            return component.host.nativeElement.innerText;
        }

        return value.name || value.value || value;
    }

    isItemBordered(value: string): boolean {
        const component = this.getComponentByValue(value);

        if (!component) {
            return false;
        }

        return component.withBorder;
    }

    onPopoverClose(): void {
        this.clearPreview();
        this.popoverOpen = false;
        this.changeDetectorRef.detectChanges();
        if (this.searchable) {
            this.resetSearch();
        }
    }

    clearPreview(): void {
        const preview = this.selectables.find(item => item.previewed);
        if (preview) {
            this.previewStop.emit();
            preview.setPreview(false);
        }
    }
}
