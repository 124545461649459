import {
    Directive,
    ElementRef,
    OnDestroy,
    OnChanges,
    Renderer2,
    Input,
    EventEmitter,
    Output
} from '@angular/core';
import { UIDragAndDropService } from './drag-and-drop.service';

/**
 * Directive that implements the native draggable feature. Used together with [bfDroppable]
 */
// tslint:disable-next-line:directive-selector old settings
@Directive({ selector: '[uiDraggable]' })
export class UIDraggableDirective implements OnChanges, OnDestroy {
    /**
     * Werther this is active or not. Pass undefined or false to disable dragging.
     */
    @Input('uiDraggable') value: any;

    /**
     * When dragging begins
     */
    @Output('uiDraggableDragStart') dragStartEmitter: EventEmitter<boolean> =
        new EventEmitter<boolean>();

    /**
     * When drag end
     */
    @Output('uiDraggableDragEnd') dragEndEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

    /**
     * DOM elements
     */
    private element: any;
    private dragArea: any = document.body;
    private helperElement: any;

    /**
     * Event references for later cleanup
     */
    private dragStartRef?: () => void;
    private dragEndRef?: () => void;

    constructor(
        private elementRef: ElementRef,
        private renderer: Renderer2,
        private dragAndDropService: UIDragAndDropService
    ) {
        this.onDragStart = this.onDragStart.bind(this);
        this.stopDragging = this.stopDragging.bind(this);

        this.element = this.elementRef.nativeElement;
    }

    /**
     * When input changes, update active state
     */
    ngOnChanges(): void {
        if (this.element) {
            // No value and empty arrays should disable drag
            if (
                this.value === undefined ||
                this.value === false ||
                (Array.isArray(this.value) && this.value.length === 0)
            ) {
                this.disableDrag();
            } else if (!this.dragStartRef) {
                this.enableDrag();
            }
        }
    }

    /**
     * When drag begin, add helper icon
     * @param event
     */
    private onDragStart(event: DragEvent): void {
        this.stopDragging();

        // Prevent drag and drop on inputs (to make sure text select can be done)
        if (event && event.target && (event.target as Element).nodeName === 'INPUT') {
            event.preventDefault();
            event.stopPropagation();
            return;
        }

        this.dragStartEmitter.emit(true);

        // Use a service to pass objects and arrays (dataTransfer.setData only supports json)
        this.dragAndDropService.startDrag(this.element, this.value);

        event.dataTransfer!.setDragImage(this.addHelper(), 0, 0);
        event.dataTransfer!.effectAllowed = 'move';
        event.dataTransfer!.dropEffect = 'move';

        this.renderer.addClass(this.element, 'ui-draggable-dragging');
        this.dragEndRef = this.renderer.listen(this.element, 'dragend', this.stopDragging);
    }

    /**
     * For us to be able to set a custom div for drag indicator we need to add a visible div for the browser to clone.
     * The original indicator is added outside screen with "position: fixed"
     */
    private addHelper(): any {
        this.removeHelper();
        const count: number = Array.isArray(this.value) ? this.value.length : 1;
        const helper: any = this.renderer.createElement('div');
        // helper.innerHTML = `${count}`;
        helper.setAttribute('data-count', count);
        this.renderer.addClass(helper, 'ui-draggable-indicator');
        this.renderer.appendChild(this.dragArea, helper);
        this.helperElement = helper;

        return helper;
    }

    /**
     * Remove helper icon from dom
     */
    private removeHelper(): void {
        if (this.helperElement) {
            this.renderer.removeChild(this.dragArea, this.helperElement);
            this.helperElement = null;
        }
    }

    /**
     * Stop drag and remove drop listeners
     * @param event
     */
    private stopDragging(): void {
        this.dragEndEmitter.emit(true);
        // this.dragAndDropService.emit()
        this.removeDragEndListener();
        this.removeHelper();
        this.dragAndDropService.reset();
        if (this.element) {
            this.renderer.removeClass(this.element, 'ui-draggable-dragging');
        }
    }

    /**
     * Remove  drag listeners. Will make directive "idle"
     */
    private removeDragStartListener(): void {
        if (this.dragStartRef) {
            this.dragStartRef();
            this.dragStartRef = undefined;
        }
    }

    /**
     * Remove listeners for drag end.
     */
    private removeDragEndListener(): void {
        if (this.dragEndRef) {
            this.dragEndRef();
            this.dragEndRef = undefined;
        }
    }

    /**
     * Disable drag on this element
     */
    private disableDrag(): void {
        this.removeDragStartListener();
        this.renderer.setAttribute(this.element, 'draggable', 'false');
        this.renderer.removeClass(this.element, 'ui-draggable');
    }

    /**
     * Enable drag on this element
     */
    private enableDrag(): void {
        this.dragStartRef = this.renderer.listen(this.element, 'dragstart', this.onDragStart);
        this.renderer.setAttribute(this.element, 'draggable', 'true');
        this.renderer.addClass(this.element, 'ui-draggable');
    }

    /**
     * Cleanup event listeners
     */
    ngOnDestroy(): void {
        // Stop current drag activity
        this.stopDragging();

        // Disable future dragging
        this.disableDrag();
    }
}
