import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CdkTreeModule } from '@angular/cdk/tree';
import { CdkTableModule } from '@angular/cdk/table';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatFormFieldModule } from '@angular/material/form-field';

// Somehow these needs to be imported directly to aviod: "ERROR in Cannot read property 'members' of undefined

/* COMPONENTS
 ***************/

// Buttons
import { UIButtonComponent } from './components/buttons/button/button.component';
import { UIButtonGroupComponent } from './components/buttons/button-group/button-group.component';
import { UIButtonGroupItemComponent } from './components/buttons/button-group/button-group-item.component';

// Collapse / Accordion
import { UIAccordionComponent } from './components/collapse/accordion/accordion.component';
import { UIAccordionItemComponent } from './components/collapse/accordion/accordion-item/accordion-item.component';
import { UIAccordionHeaderTemplateDirective } from './components/collapse/accordion/templates/ui-accordion-header-template.directive';

// Dialogs
import { UIDialogComponent } from './components/dialogs/dialog/dialog.component';
import { UIDialogButtonsComponent } from './components/dialogs/dialog/dialog-buttons/dialog-buttons.component';
import { UIDialogDirective } from './components/dialogs/dialog/dialog.directive';
import { UIDialogTemplateDirective } from './components/dialogs/dialog/dialog-template.directive';
import { UIAlertDialogComponent } from './components/dialogs/alert-dialog/alert-dialog.component';
import { UIConfirmDialogComponent } from './components/dialogs/confirm-dialog/confirm-dialog.component';
import { UIErrorDialogComponent } from './components/dialogs/error-dialog/error-dialog.component';

// Icons
import { UIIconComponent } from './components/icon/icon/icon.component';
import { UIFlagComponent } from './components/icon/flag/flag.component';
import { UIImageComponent } from './components/icon/image/image.component';
import { UISVGIconComponent } from './components/icon/svg-icon/svg-icon.component';
import { UISvgIconsComponent } from './components/icon/svg-icon/svg-icons.component';

// Inputs
import { UITextareaComponent } from './components/inputs/textarea/textarea.component';
import { UIDatePickerComponent } from './components/inputs/date-picker/date-picker.component';
import { UIInputComponent } from './components/inputs/input/input.component';
import { UINumberInputComponent } from './components/inputs/number/number-input.component';
import { UICheckboxComponent } from './components/inputs/checkbox/checkbox.component';
import { UIToggleSwitchComponent } from './components/inputs/toggle-switch/toggle-switch.component';
import { UIRadioComponent } from './components/inputs/radio/radio.component';
import { UIInlineEditComponent } from './components/inputs/inline-edit/inline-edit.component';
import { UIFileDropzoneComponent } from './components/inputs/file-dropzone/file-dropzone.component';

// Layout
import { UIBodyComponent } from './components/layout/body/body.component';
import { UIHeaderComponent } from './components/layout/header/header.component';
import { UIPanelComponent } from './components/layout/panel/panel.component';
import { UITabsComponent } from './components/layout/tabs/tabs.component';
import { UITabComponent } from './components/layout/tabs/tab.component';
import { UIIframeComponent } from './components/layout/iframe/iframe.component';

// Loader
import { UILoaderComponent } from './components/loader/loader/loader.component';
import { UIInlineLoaderComponent } from './components/graphics/inline-loader/inline-loader.component';

// Logo
import { UILogoComponent } from './components/logo/logo.component';

// Navigation
import { UIMenuSidePanelComponent } from './components/navigation/menu/ui-menu-side-panel.component';
import { UIMenuItemComponent } from './components/navigation/menu/ui-menu-item.component';
import { UIMenuGroupComponent } from './components/navigation/menu/ui-menu-group.component';
import { UIBreadcrumbComponent } from './components/navigation/breadcrumbs/breadcrumb.component';
import { UIBreadcrumbsComponent } from './components/navigation/breadcrumbs/breadcrumbs.component';

// Popovers
import { UIPopoverComponent } from './components/popovers/popover/popover.component';
import { UIPopoverDirective } from './components/popovers/popover/popover.directive';
import { UIPopoverTargetDirective } from './components/popovers/popover/popover-target.directive';
import { UIPopoverTemplateDirective } from './components/popovers/popover/popover-template.directive';
import { UITooltipComponent } from './components/popovers/tooltip/tooltip.component';
import { UITooltipDirective } from './components/popovers/tooltip/tooltip.directive';
import { UIDropdownComponent } from './components/popovers/dropdown/dropdown.component';
import { UIDropdownTargetDirective } from './components/popovers/dropdown/dropdown.directive';
import {
    UIDropdownItemComponent,
    UIDropdownDividerComponent
} from './components/popovers/dropdown/dropdown-item.component';
import { UIDropdownInputComponent } from './components/popovers/dropdown/dropdown-input.component';

// Select
import { UISelectableListComponent } from './components/inputs/selectable-list/selectable-list.component';
import { UISelectableBaseDirective } from './components/inputs/selectable-list/selectable.component';
import { UISelectableDirective } from './components/inputs/selectable-list/selectable.directive';
import { UISelectComponent } from './components/select/select/select.component';
import { UISelectTokenFieldTemplateDirective } from './components/select/select/templates/select-token-field-template.directive';
import { UIOptionComponent } from './components/select/select/option.component';

// List & table
import { UIListComponent } from './components/layout/list/list.component';
import { UIListCellTemplateDirective } from './components/layout/list/templates/list-cell-template.directive';
import { UIListHeaderTemplateDirective } from './components/layout/list/templates/list-header-template.directive';
import { UIListColumnDirective } from './components/layout/list/templates/list-column.directive';
import { UIListGridDirective } from './components/layout/list/templates/list-grid.directive';
import { UIListFolderTemplateDirective } from './components/layout/list/templates/list-folder-template.directive';
import { UIListCellComponent } from './components/layout/list/components/cell/list-cell.component';
import { UIListHeaderComponent } from './components/layout/list/components/header/list-header.component';
import { UIListFolderCellComponent } from './components/layout/list/components/folder/list-folder-cell.component';
import { UIListHeaderCellComponent } from './components/layout/list/components/header/cell/list-header-cell.component';
import { UIListGridCellComponent } from './components/layout/list/components/grid-cell/list-grid-cell.component';
import { UITableComponent } from './components/layout/table/table.component';
import { UISkeletonCellComponent } from './components/layout/table/skeleton-cell/skeleton-cell.component';
import { MatSortModule } from '@angular/material/sort';

// Static components
import { UIHelpMenuComponent } from './components/static/help-menu/help-menu.component';

/* DIRECTIVES
 ***************/
import { UIDraggableDirective } from './directives/drag-and-drop/draggable.directive';
import { UIDropZoneDirective } from './directives/drag-and-drop/drop-zone.directive';
import { UIInViewDirective } from './directives/in-view.directive';
import { UIPreventClickDirective } from './directives/prevent-click.directive';
import { UISelectLabelDirective } from './components/select/select/select.directive';
import { UIDragDropDirective } from './directives/dragdrop/dragdrop.directive';
import { UIDropDirective } from './directives/dragdrop/drop.directive';

// Notification
import { UINotificationComponent } from './components/notification/notification/notification.component';
import { UIStickyNotificationComponent } from './components/notification/sticky-notification/sticky-notification.component';

/* PIPES
 **********/
import { UIFormatBytesPipe } from './pipes/formatBytes';
import { UIFileExtensionPipe } from './pipes/fileExtension';
import { UIOrdinalPipe } from './pipes/ordinal';

/* Paginator
 **********/
import { UIPaginatorComponent } from './components/paginator/paginator/paginator.component';

@NgModule({
    imports: [
        CommonModule,
        OverlayModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        CdkTreeModule,
        CdkTableModule,
        MatSortModule,
        UIDropDirective,
        MatFormFieldModule
    ],
    declarations: [
        UIBodyComponent,
        UIButtonComponent,
        UIButtonGroupComponent,
        UIButtonGroupItemComponent,
        UIDraggableDirective,
        UIDropZoneDirective,
        UIDragDropDirective,
        UIInViewDirective,
        UITextareaComponent,
        UIListComponent,
        UIListCellTemplateDirective,
        UIListColumnDirective,
        UIListGridDirective,
        UIListHeaderTemplateDirective,
        UIListFolderTemplateDirective,
        UIListCellComponent,
        UIListGridCellComponent,
        UIListHeaderComponent,
        UIListHeaderCellComponent,
        UIListFolderCellComponent,
        UIInlineLoaderComponent,
        UIMenuSidePanelComponent,
        UIMenuGroupComponent,
        UIMenuItemComponent,
        UIDatePickerComponent,
        UILogoComponent,
        UIDialogDirective,
        UIDialogTemplateDirective,
        UIDialogComponent,
        UIDialogButtonsComponent,
        UIHeaderComponent,
        UIIconComponent,
        UIFlagComponent,
        UIImageComponent,
        UISVGIconComponent,
        UISvgIconsComponent,
        UIPanelComponent,
        UITabsComponent,
        UITabComponent,
        UIAlertDialogComponent,
        UIConfirmDialogComponent,
        UIPopoverDirective,
        UIPopoverTemplateDirective,
        UIAccordionHeaderTemplateDirective,
        UIPopoverComponent,
        UIPopoverTargetDirective,
        UISelectableListComponent,
        UISelectableBaseDirective,
        UISelectableDirective,
        UISelectComponent,
        UISelectLabelDirective,
        UISelectTokenFieldTemplateDirective,
        UIOptionComponent,
        UIBreadcrumbComponent,
        UIBreadcrumbsComponent,
        UIInputComponent,
        UINumberInputComponent,
        UICheckboxComponent,
        UIToggleSwitchComponent,
        UIAccordionComponent,
        UIAccordionItemComponent,
        UILoaderComponent,
        UITooltipComponent,
        UITooltipDirective,
        UIFormatBytesPipe,
        UIFileExtensionPipe,
        UIOrdinalPipe,
        UIDropdownComponent,
        UIDropdownItemComponent,
        UIDropdownDividerComponent,
        UIDropdownTargetDirective,
        UIDropdownInputComponent,
        UIIframeComponent,
        UIPreventClickDirective,
        UIErrorDialogComponent,
        UINotificationComponent,
        UIRadioComponent,
        UIHelpMenuComponent,
        UITableComponent,
        UIPaginatorComponent,
        UISkeletonCellComponent,
        UIStickyNotificationComponent,
        UIInlineEditComponent,
        UIFileDropzoneComponent
    ],
    exports: [
        CdkTableModule,
        UIBodyComponent,
        UIButtonComponent,
        UIButtonGroupComponent,
        UIButtonGroupItemComponent,
        UIDraggableDirective,
        UIDropZoneDirective,
        UIDragDropDirective,
        UIDropDirective,
        UIInViewDirective,
        UIInlineLoaderComponent,
        UITextareaComponent,
        UIDatePickerComponent,
        UIListComponent,
        UIListCellTemplateDirective,
        UIListColumnDirective,
        UIListGridDirective,
        UIListHeaderTemplateDirective,
        UIListFolderTemplateDirective,
        UIAccordionHeaderTemplateDirective,
        UIMenuSidePanelComponent,
        UIMenuGroupComponent,
        UIMenuItemComponent,
        UILogoComponent,
        UIDialogDirective,
        UIDialogTemplateDirective,
        UIDialogComponent,
        UIDialogButtonsComponent,
        UIHeaderComponent,
        UIIconComponent,
        UISVGIconComponent,
        UIListColumnDirective,
        UIListGridDirective,
        UIFlagComponent,
        UIImageComponent,
        UIPanelComponent,
        UITabsComponent,
        UITabComponent,
        UIAlertDialogComponent,
        UIConfirmDialogComponent,
        UIPopoverDirective,
        UIPopoverTemplateDirective,
        UIPopoverComponent,
        UIPopoverTargetDirective,
        UISelectableListComponent,
        UISelectableBaseDirective,
        UISelectableDirective,
        UISelectComponent,
        UISelectLabelDirective,
        UISelectTokenFieldTemplateDirective,
        UIOptionComponent,
        UIBreadcrumbComponent,
        UIBreadcrumbsComponent,
        UIInputComponent,
        UINumberInputComponent,
        UICheckboxComponent,
        UIToggleSwitchComponent,
        UIAccordionComponent,
        UIAccordionItemComponent,
        UILoaderComponent,
        UITooltipComponent,
        UITooltipDirective,
        UIFormatBytesPipe,
        UIFileExtensionPipe,
        UIOrdinalPipe,
        UIDropdownComponent,
        UIDropdownItemComponent,
        UIDropdownDividerComponent,
        UIDropdownTargetDirective,
        UIDropdownInputComponent,
        UIIframeComponent,
        UIErrorDialogComponent,
        UINotificationComponent,
        UIRadioComponent,
        UIHelpMenuComponent,
        UITableComponent,
        UIPaginatorComponent,
        UISkeletonCellComponent,
        UIStickyNotificationComponent,
        UIInlineEditComponent,
        UIFileDropzoneComponent
    ],
    providers: [UIFormatBytesPipe, UIFileExtensionPipe, UIOrdinalPipe]
})
export class UIModule {}
