import { Pipe, PipeTransform } from '@angular/core';

/*
 * Append ordinal to number (e.g. "1st" position)
 * Usage:
 *   value | ordinal:keepNumber
 * Example:
 *   {{ 23 |  ordinal}}
 *   formats to: '23rd'
 * Example:
 *   {{ 23 |  ordinal:false}}
 *   formats to: 'rd'
 */
@Pipe({ name: 'uiOrdinal' })
export class UIOrdinalPipe implements PipeTransform {
    transform(n: number, keepNumber: boolean = true): string {
        return this.uiOrdinal(n, keepNumber);
    }

    private uiOrdinal(n: number, keepNumber: boolean = true): string {
        const ordinals: string[] = ['th', 'st', 'nd', 'rd'];
        const v = n % 100;
        return (keepNumber ? n : '') + (ordinals[(v - 20) % 10] || ordinals[v] || ordinals[0]);
    }
}
