/* eslint-disable no-console */
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UIConfirmDialogService } from '../../components/dialogs/confirm-dialog/confirm-dialog.service';

@Component({
    // Demo Project components should not be forced to use ui- prefix
    // tslint:disable-next-line:component-selector
    selector: 'component-docs',
    styleUrls: ['component-docs.component.scss'],
    templateUrl: 'component-docs.component.html'
})
export class ComponentDocsComponent {
    isOpen: boolean;
    values = [
        { text: 'Text 1', value: '1' },
        { text: 'Text 2', value: '2' },
        { text: 'Text 3', value: '3' },
        { text: 'Text 4', value: '4' },
        { text: 'Text 5', value: '5' },
        { text: 'Text 6', value: '6' },
        { text: 'Text 7', value: '7' },
        { text: 'Text 8', value: '8' },
        { text: 'Text 9', value: '9' },
        { text: 'Text 10', value: '10' },
        { text: 'Text 11', value: '11' },
        { text: 'Text 12', value: '12' },
        { text: 'Text 13', value: '14' }
    ];
    selectValue: string | undefined = undefined;

    loading = false;

    constructor(
        public confirmDialogService: UIConfirmDialogService,
        public activatedRoute: ActivatedRoute
    ) {
        // const search = new URLSearchParams(location.search);
        // const theme = search.get('theme');
        // if (theme) {
        //     this.theme = theme as any;
        //     document.body.setAttribute('ui-theme', theme);
        // }
        // else {
        //     this.updateTheme('default');
        // }
    }

    public showPreview(_file?: any): void {
        // let dialogRef: UIDialogRef = this.previewDialog.open({
        //   image: file
        // });
    }

    test(): void {
        this.selectValue = this.values[2].value;
    }

    public async confirm(): Promise<void> {
        if (await this.confirmDialogService.confirm({ headerText: 'Confirm' })) {
            console.log('JA');
        } else {
            console.log('NEJ');
        }
    }
}
