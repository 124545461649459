<!--DATE PICKER -->
<div
    [className]="outerBorders ? 'date-picker-bordered' : 'date-picker-unbordered'"
    [ngClass]="{ 'date-picker-compact': isCompact }"
    *ngIf="!showYearPicker && !showHourPicker">
    <div class="current-month">
        <ui-icon
            *ngIf="!isCompact; else arrowLeftIcon"
            class="step-month"
            icon="arrow-left-b"
            (click)="prevMonth()">
        </ui-icon>
        <ng-template #arrowLeftIcon>
            <ui-svg-icon
                class="step-month"
                icon="arrow-left"
                (click)="prevMonth()" />
        </ng-template>
        <div
            class="month-name"
            (click)="openYearPicker()">
            {{ currentDate.format('MMM') }} {{ currentDate.format('YYYY') }}
        </div>
        <ui-icon
            *ngIf="!isCompact; else arrowRightIcon"
            class="step-month"
            icon="arrow-right-b"
            (click)="nextMonth()">
        </ui-icon>
        <ng-template #arrowRightIcon>
            <ui-svg-icon
                *ngIf="isCompact"
                class="step-month"
                icon="arrow-right"
                (click)="nextMonth()" />
        </ng-template>
    </div>
    <div class="day-names">
        <div
            *ngIf="firstWeekdaySunday"
            class="day">
            Sun
        </div>
        <div class="day">Mon</div>
        <div class="day">Tue</div>
        <div class="day">Wed</div>
        <div class="day">Thu</div>
        <div class="day">Fri</div>
        <div class="day">Sat</div>
        <div
            *ngIf="!firstWeekdaySunday"
            class="day">
            Sun
        </div>
    </div>
    <div class="calendar date">
        <div
            class="item"
            *ngFor="let d of days; let i = index; trackBy: trackByDate"
            (mouseenter)="hoverDate(d.dayjsObj, $event)"
            (click)="onDateClick(d.dayjsObj)"
            [ngClass]="{
                disabled: !d.enabled,
                today: d.today,
                'selected-first': d.selected.first,
                'selected-last': d.selected.last,
                'selected-between': d.selected.between,
                'out-of-month': d.outOfMonth
            }">
            <div
                class="inner"
                [attr.data-testid]="'selected-' + d.day">
                <span class="inner-content">
                    {{ d.day }}
                </span>
            </div>
        </div>
    </div>
</div>

<!--YEAR PICKER-->
<div
    class="year-picker"
    *ngIf="showYearPicker">
    <div class="calendar year">
        <div
            class="item"
            *ngFor="let year of years; let i = index"
            (click)="selectYear(year, $event)">
            <div class="inner">
                {{ year }}
            </div>
        </div>
    </div>
</div>

<!--HOUR PICKER-->
<div
    *ngIf="showHourPicker"
    class="hour-picker">
    <div class="calendar hour">
        <div
            *ngFor="let hour of hours; let i = index"
            class="item"
            (click)="selectHour(i, $event)">
            <div class="inner">{{ i < 10 ? '0' + i : i }}:00</div>
        </div>
    </div>
</div>
