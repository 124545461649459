import {
    Directive,
    ElementRef,
    OnDestroy,
    OnChanges,
    Renderer2,
    Input,
    EventEmitter,
    Output
} from '@angular/core';
import { UIDragAndDropService } from './drag-and-drop.service';

@Directive({
    // Current lint rules forces directives to be kebab-case
    // TODO: Either change the ruleset or update all the references of this directive
    // tslint:disable-next-line:directive-selector
    selector: '[uiDropZone]'
})
export class UIDropZoneDirective implements OnChanges, OnDestroy {
    /**
     * Directive can take a value to control if it active or not.
     * Pass false to disable this drop zone
     */
    @Input('uiDropZone') value: any;

    /**
     * When something is drop at the drop zone
     */
    @Output('uiDropZoneDrop') dropEventEmitter: EventEmitter<UIDropZoneEvent> =
        new EventEmitter<UIDropZoneEvent>();

    /**
     * When dragged element is over this drop zone
     */
    @Output('uiDropZoneEnter') dragEnterEventEmitter: EventEmitter<UIDropZoneEvent> =
        new EventEmitter<UIDropZoneEvent>();

    /**
     * When dragged element leaves drop
     */
    @Output('uiDropZoneLeave') dragLeaveEventEmitter: EventEmitter<UIDropZoneEvent> =
        new EventEmitter<UIDropZoneEvent>();

    /**
     * DOM elemenets
     */
    private element: any;

    /**
     * Listener references
     */
    private dragEnterRef?: () => void;
    private dragLeaveRef?: () => void;
    private dragOverRef?: () => void;
    private dropRef?: () => void;
    private mouseUpRef?: () => void;

    constructor(
        private elementRef: ElementRef,
        private renderer: Renderer2,
        private dragAndDropService: UIDragAndDropService
    ) {
        this.onDragLeave = this.onDragLeave.bind(this);
        this.onDragEnter = this.onDragEnter.bind(this);
        this.onDragOver = this.onDragOver.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.element = this.elementRef.nativeElement;
    }

    /**
     * When input of active / disabled changes
     */
    ngOnChanges(): void {
        // false will disable this
        if (this.value !== false) {
            if (!this.dragEnterRef) {
                this.renderer.addClass(this.element, 'ui-drop-zone');
                this.dragEnterRef = this.renderer.listen(this.element, 'dragenter', this.onDragEnter);
            }
        } else {
            this.removeDragEnterListener();
        }
    }

    /**
     * Reset drop zone to normal state.
     */
    reset(): void {
        this.removeDragOverListener();
        this.removeDragLeaveListener();
        this.removeDropListener();
        this.renderer.removeClass(this.element, 'ui-drop-zone-over');
    }

    /**
     * Needed to enable this element as a droptarget (preventDefault and/or return false).
     * @param event
     */
    private onDragLeave(): void {
        const dragEvent = this.getEventData();
        if (dragEvent) {
            this.dragLeaveEventEmitter.emit(dragEvent);
        }
        this.reset();
    }

    /**
     * Needed to enable this element as a droptarget (preventDefault and/or return false)
     * @param event
     */
    private onDragOver(event: MouseEvent): boolean | void {
        event.preventDefault();
        event.stopPropagation();
        return false;
    }

    private onDragEnter(event: MouseEvent): boolean | void {
        this.reset();

        // To keep listeners to a minimum, add the when actually hovering
        if (this.element !== this.dragAndDropService.element) {
            this.dragOverRef = this.renderer.listen(this.element, 'dragover', this.onDragOver);
            this.dragLeaveRef = this.renderer.listen(this.element, 'dragleave', this.onDragLeave);
            this.dropRef = this.renderer.listen(this.element, 'drop', this.onDrop);
            this.renderer.addClass(this.element, 'ui-drop-zone-over');
            const dragEvent = this.getEventData();

            if (dragEvent) {
                // Enter should fire after leave to increase ease to use.
                setTimeout(() => {
                    this.dragEnterEventEmitter.emit(dragEvent);
                });
            }
        }

        event.preventDefault();

        return false;
    }

    private onDrop(event: DragEvent): boolean {
        const dropEvent = this.getEventData();

        if (dropEvent) {
            this.dropEventEmitter.emit(dropEvent);

            // Trigger out event
            this.dragLeaveEventEmitter.emit(dropEvent);
        }
        this.dragAndDropService.reset();
        this.reset();

        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }

        return false;
    }

    private getEventData(): UIDropZoneEvent | undefined {
        const dragData: any = this.dragAndDropService.value;
        const dragElement: any = this.dragAndDropService.element;

        if (dragElement && dragElement !== this.element) {
            const dropEvent: UIDropZoneEvent = new UIDropZoneEvent({
                dragData,
                dragElement,
                dropZoneData: this.value,
                dropZoneElement: this.element
            });

            return dropEvent;
        }

        return undefined;
    }

    private removeDragOverListener(): void {
        if (this.dragOverRef) {
            this.dragOverRef();
            this.dragOverRef = undefined;
        }
    }

    private removeDragLeaveListener(): void {
        if (this.dragLeaveRef) {
            this.dragLeaveRef();
            this.dragLeaveRef = undefined;
        }
    }

    private removeDragEnterListener(): void {
        if (this.dragEnterRef) {
            this.dragEnterRef();
            this.dragEnterRef = undefined;
            this.renderer.addClass(this.element, 'ui-drop-zone');
        }
    }

    private removeDropListener(): void {
        if (this.dropRef) {
            this.dropRef();
            this.dropRef = undefined;
        }
    }

    ngOnDestroy(): void {
        this.reset();

        this.removeDragEnterListener();

        if (this.mouseUpRef) {
            this.mouseUpRef();
        }
    }
}

export class UIDropZoneEvent {
    public dragElement: any;
    public dropZoneElement: any;
    public dragData: any;
    public dropZoneData: any;

    constructor(data: Partial<UIDropZoneEvent> = {}) {
        Object.assign(this, data);
    }
}
