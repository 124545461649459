import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'uiFileExtension'
})
export class UIFileExtensionPipe implements PipeTransform {
    transform(value: string): string {
        if (!value || value.indexOf('.') === -1) {
            return '';
        }
        return value.split('.')[value.split('.').length - 1];
    }
}
