import { InjectionToken } from '@angular/core';

export const CREATIVE_PREVIEW_HOST = new InjectionToken<string>('CreativePreviewHost');

export type PreviewType = 'animated' | 'image';

export interface IDynamicPropertyData {
    id: string;
    value: string;
}
