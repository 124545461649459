import {
    ElementRef,
    Directive,
    ViewContainerRef,
    ViewRef,
    Input,
    OnDestroy,
    OnChanges,
    SimpleChanges,
    AfterViewInit
} from '@angular/core';
import { UIPopoverTargetDirective } from '../popover/popover-target.directive';
import { UITooltipService } from './tooltip.service';
import { Subscription, fromEvent, merge, of } from 'rxjs';
import { UITooltipComponent } from './tooltip.component';
import { map, delay, switchMap } from 'rxjs/operators';
type TooltipType = 'default' | 'discrete';

@Directive({
    // Current lint rules forces directives to be kebab-case
    // TODO: Either change the ruleset or update all the references of this directive
    // tslint:disable-next-line:directive-selector
    selector: '[uiTooltip]',
    providers: [UIPopoverTargetDirective],
    exportAs: 'uiTooltip'
})
export class UITooltipDirective implements AfterViewInit, OnChanges, OnDestroy {
    /**
     * @summary:
     * Tooltip text
     */
    @Input() uiTooltip: string | undefined;

    /**
     * @summary:
     * The width of the tooltip. Defaults to auto.
     */
    @Input() uiTooltipWidth: number | 'auto' = 'auto';

    /**
     * @summary:
     * The width of the tooltip. Defaults to auto. Value and unit combination is required (e.g 200px)
     */
    @Input() uiTooltipMaxWidth: string | 'none' = 'none';

    /**
     * @summary:
     * If the tooltip arrow should be hidden
     */
    @Input() uiTooltipHideArrow: boolean;

    /**
     * @summary:
     * The position of the tooltip. top, left, bottom, right. Defaults to bottom
     */
    @Input() uiTooltipPosition: 'top' | 'left' | 'bottom' | 'right' = 'top';

    /**
     * @summary:
     * Custom class for the tooltip
     */
    @Input() uiTooltipClass = 'ui-tooltip';

    /**
     * @summary:
     * If the tooltip should match the host element's width
     */
    @Input() uiTooltipUseTargetWidth = false;

    /**
     * @summary:
     * What should trigger the tooltip to be shown
     */
    @Input() uiTooltipTrigger: 'click' | 'hover' = 'hover';

    /**
     * @summary:
     * Disables the tooltip triggers
     */
    @Input() uiTooltipDisabled: boolean;

    /**
     * @summary:
     * Disables the tooltip triggers
     */
    @Input() uiTooltipOnlyWhenTruncated: boolean;

    /**
     * @summary:
     * Set a different trigger rather than the initial host element
     */
    @Input() uiTooltipTriggerHost: HTMLElement;

    /**
     * @summary:
     * Set a type/theme
     */
    @Input() uiTooltipType: TooltipType = 'default';

    /**
     * @summary:
     * Set a delay in ms
     */
    @Input() uiTooltipDelay = 250;

    /**
     * @summary:
     * Set if the tooltip should be interactive
     * Useful to have enabled if user should be able to click on links
     * etc. in the tooltip
     */
    @Input() uiTooltipInteractive: boolean;

    @Input() uiTooltipTrustedContent: boolean;

    private isSpawned: boolean;
    private tooltip?: UITooltipComponent;
    private tooltipHost: ViewRef;
    private target: UIPopoverTargetDirective;
    private isMouseOver = false;
    private tooltipSubscription = Subscription.EMPTY;
    private tooltipServiceSubscription = Subscription.EMPTY;

    constructor(
        private host: ElementRef,
        private viewContainerRef: ViewContainerRef,
        private tooltipService: UITooltipService
    ) {
        this.target = new UIPopoverTargetDirective(this.host);
    }

    ngAfterViewInit(): void {
        if (!this.uiTooltipTriggerHost) {
            this.uiTooltipTriggerHost = this.host.nativeElement;
        }

        const event = this.uiTooltipTrigger === 'click' ? 'click' : 'mouseover';

        const hide$ = merge(
            fromEvent<MouseEvent>(this.uiTooltipTriggerHost, 'mouseleave'),
            fromEvent<MouseEvent>(this.uiTooltipTriggerHost, 'mousewheel')
        ).pipe(map(_ => false));

        const show$ = fromEvent<MouseEvent>(this.uiTooltipTriggerHost, event).pipe(map(_ => true));

        this.tooltipSubscription = merge(hide$, show$)
            .pipe(
                switchMap(show => {
                    if (!show) {
                        return of(false);
                    }
                    return of(true).pipe(delay(this.uiTooltipDelay));
                })
            )
            .subscribe(show => {
                if (show) {
                    this.create();
                    this.isMouseOver = true;
                } else {
                    this.onMouseOut();
                }
            });
    }

    onMouseOut = () => {
        if (this.tooltip && this.isSpawned) {
            this.close();
        }
    };

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['uiTooltip'] && this.tooltip) {
            const text = changes['uiTooltip'].currentValue;
            if (text) {
                this.tooltip.text = text;
            } else {
                this.close();
            }
        } else if (changes['uiTooltip'] && this.isMouseOver) {
            this.create();
            const text = changes['uiTooltip'].currentValue;
            if (text && this.tooltip) {
                this.tooltip.text = text;
            }
        } else if (changes['uiTooltip']) {
            if (changes['uiTooltip'].currentValue) {
                this.close();
            }
        }
    }

    create(): void {
        const element = this.host.nativeElement;

        if (this.uiTooltipOnlyWhenTruncated) {
            this.uiTooltipDisabled = element.offsetWidth >= element.scrollWidth;
        }

        if (this.isSpawned || this.uiTooltipDisabled) {
            return;
        } else {
            this.viewContainerRef && this.viewContainerRef.clear();
            this.isSpawned = false;
        }

        if (!this.tooltipServiceSubscription) {
            this.tooltipServiceSubscription = this.tooltipService.onClose.subscribe(() => this.close());
        }

        const tooltipRef = this.viewContainerRef.createComponent(UITooltipComponent);
        this.tooltipHost = tooltipRef.hostView;
        this.tooltip = tooltipRef.instance;
        this.viewContainerRef.insert(this.tooltipHost);
        this.tooltip.text = this.uiTooltip ? this.uiTooltip : '';

        this.configureTooltip();
    }

    private configureTooltip(): void {
        this.tooltip!.popover.config = {
            panelClass: this.uiTooltipClass,
            hasBackdrop: false,
            arrowPosition: this.uiTooltipHideArrow ? undefined : this.uiTooltipPosition,
            width: this.uiTooltipWidth || 'auto',
            minWidth: 'auto',
            maxWidth: this.uiTooltipMaxWidth,
            useTargetWidth: this.uiTooltipUseTargetWidth,
            position: this.uiTooltipPosition,
            popoverType: this.uiTooltipType,
            uiTooltipInteractive: this.uiTooltipInteractive
        };

        this.openTooltip();
    }

    private openTooltip(): void {
        if (!this.uiTooltip) {
            throw new Error(
                'The tooltip is missing a text. Set a value on uiTooltip or set uiTooltipDisabled to true to disable.'
            );
        }
        if (!this.uiTooltipDelay) {
            this.uiTooltipDelay = 1;
        }

        this.tooltip?.open(this.target, this);
        this.isSpawned = true;
    }

    close(): void {
        if (this.isSpawned && !this.uiTooltipInteractive) {
            this.tooltip?.close();
        }
        this.isSpawned = false;
        this.isMouseOver = false;
    }

    ngOnDestroy(): void {
        if (this.tooltip) {
            this.viewContainerRef.clear();
            this.isSpawned && this.close();
        }
        this.tooltipServiceSubscription.unsubscribe();
        this.tooltipSubscription.unsubscribe();
    }
}
