import { Component, ViewChild } from '@angular/core';
import { UIListDataSource } from '../models/list-data-source';
import { IUIListDataNode } from '../models/list-data-node';
import { IUIEditedCellData } from '../models';
import { UIListComponent } from 'components';
import { UIListDropEvent } from '../../../../types/list';

const expandableCheckboxData = [
    {
        id: 1,
        title: 'I have two children',
        children: [
            {
                id: 998,
                title: 'child one',
                disabled: true,
            },
            {
                id: 999,
                title: 'child two',
            }
        ]
    },
    {
        id: 2,
        title: 'I have one child',
        children: [
            {
                id: 2,
                title: 'B 1',
            }
        ]
    },
    {
        id: 3,
        title: 'I have empty children arr',
        children: []
    },
    {
        id: 4,
        title: 'I have not even children arr',
        disabled: true
    }
];

const PHOTO_DATA = [
    {
        'albumId': 1,
        'id': 1,
        'title': 'accusamus beatae ad facilis cum similique qui sunt',
        'url': 'https://via.placeholder.com/600/92c952',
        'thumbnailUrl': 'https://via.placeholder.com/150/92c952'
    },
    {
        'albumId': 1,
        'id': 2,
        'title': 'reprehenderit est deserunt velit ipsam',
        'url': 'https://via.placeholder.com/600/771796',
        'thumbnailUrl': 'https://via.placeholder.com/150/771796'
    },
    {
        'albumId': 1,
        'id': 3,
        'title': 'officia porro iure quia iusto qui ipsa ut modi',
        'url': 'https://via.placeholder.com/600/24f355',
        'thumbnailUrl': 'https://via.placeholder.com/150/24f355'
    },
    {
        'albumId': 1,
        'id': 4,
        'title': 'culpa odio esse rerum omnis laboriosam voluptate repudiandae',
        'url': 'https://via.placeholder.com/600/d32776',
        'thumbnailUrl': 'https://via.placeholder.com/150/d32776'
    },
    {
        'albumId': 1,
        'id': 5,
        'title': 'natus nisi omnis corporis facere molestiae rerum in',
        'url': 'https://via.placeholder.com/600/f66b97',
        'thumbnailUrl': 'https://via.placeholder.com/150/f66b97'
    },
    {
        'albumId': 1,
        'id': 6,
        'title': 'accusamus ea aliquid et amet sequi nemo',
        'url': 'https://via.placeholder.com/600/56a8c2',
        'thumbnailUrl': 'https://via.placeholder.com/150/56a8c2'
    },
    {
        'albumId': 1,
        'id': 7,
        'title': 'officia delectus consequatur vero aut veniam explicabo molestias',
        'url': 'https://via.placeholder.com/600/b0f7cc',
        'thumbnailUrl': 'https://via.placeholder.com/150/b0f7cc'
    },
    {
        'albumId': 1,
        'id': 8,
        'title': 'aut porro officiis laborum odit ea laudantium corporis',
        'url': 'https://via.placeholder.com/600/54176f',
        'thumbnailUrl': 'https://via.placeholder.com/150/54176f'
    },
    {
        'albumId': 1,
        'id': 9,
        'title': 'qui eius qui autem sed',
        'url': 'https://via.placeholder.com/600/51aa97',
        'thumbnailUrl': 'https://via.placeholder.com/150/51aa97'
    },
    {
        'albumId': 1,
        'id': 10,
        'title': 'beatae et provident et ut vel',
        'url': 'https://via.placeholder.com/600/810b14',
        'thumbnailUrl': 'https://via.placeholder.com/150/810b14'
    },
    {
        'albumId': 1,
        'id': 11,
        'title': 'nihil at amet non hic quia qui',
        'url': 'https://via.placeholder.com/600/1ee8a4',
        'thumbnailUrl': 'https://via.placeholder.com/150/1ee8a4'
    },
    {
        'albumId': 1,
        'id': 12,
        'title': 'mollitia soluta ut rerum eos aliquam consequatur perspiciatis maiores',
        'url': 'https://via.placeholder.com/600/66b7d2',
        'thumbnailUrl': 'https://via.placeholder.com/150/66b7d2'
    },
    {
        'albumId': 1,
        'id': 13,
        'title': 'repudiandae iusto deleniti rerum',
        'url': 'https://via.placeholder.com/600/197d29',
        'thumbnailUrl': 'https://via.placeholder.com/150/197d29'
    },
    {
        'albumId': 1,
        'id': 14,
        'title': 'est necessitatibus architecto ut laborum',
        'url': 'https://via.placeholder.com/600/61a65',
        'thumbnailUrl': 'https://via.placeholder.com/150/61a65'
    },
    {
        'albumId': 1,
        'id': 15,
        'title': 'harum dicta similique quis dolore earum ex qui',
        'url': 'https://via.placeholder.com/600/f9cee5',
        'thumbnailUrl': 'https://via.placeholder.com/150/f9cee5'
    },
    {
        'albumId': 1,
        'id': 16,
        'title': 'iusto sunt nobis quasi veritatis quas expedita voluptatum deserunt',
        'url': 'https://via.placeholder.com/600/fdf73e',
        'thumbnailUrl': 'https://via.placeholder.com/150/fdf73e'
    },
    {
        'albumId': 1,
        'id': 17,
        'title': 'natus doloribus necessitatibus ipsa',
        'url': 'https://via.placeholder.com/600/9c184f',
        'thumbnailUrl': 'https://via.placeholder.com/150/9c184f'
    },
    {
        'albumId': 1,
        'id': 18,
        'title': 'laboriosam odit nam necessitatibus et illum dolores reiciendis',
        'url': 'https://via.placeholder.com/600/1fe46f',
        'thumbnailUrl': 'https://via.placeholder.com/150/1fe46f'
    },
    {
        'albumId': 1,
        'id': 19,
        'title': 'perferendis nesciunt eveniet et optio a',
        'url': 'https://via.placeholder.com/600/56acb2',
        'thumbnailUrl': 'https://via.placeholder.com/150/56acb2'
    },
];

const TREE_DATA = [
    {
        id: 1,
        title: 'Not a folder',
        disabled: true
    },
    {
        id: 1,
        title: 'This folder has the longest name in the world, This folder has the longest name in the world, This folder has the longest name in the world, This folder has the longest name in the world',
        children: [
            {
                id: 2,
                title: 'Sub Folder 1',
            },
            {
                id: 3,
                title: 'Sub Folder 2',
            }
        ]
    },
    {
        id: 4,
        title: 'Folder 2',
        children: []
    },
    {
        id: 5,
        title: 'Folder 3',
        children: [
            {
                id: 6,
                title: 'Subfolder 3',
                children: [
                    {
                        id: 7,
                        title: 'Subfolder 4',
                        children: [
                            {
                                id: 9,
                                title: 'Not a folder',
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        id: 8,
        title: 'Load more, empty from beginning',
        totalCount: 120,
        children: []
    },
];

const LOAD_MORE = [
    {
        id: 1,
        title: 'load more 1',
        children: [
            {
                id: 2,
                title: 'Sub load more 1',
            },
            {
                id: 3,
                title: 'Sub load more 2',
            }
        ]
    },
    {
        id: 4,
        title: 'load more 2',
        children: []
    },
    {
        id: 5,
        title: 'load more 3',
        children: [
            {
                id: 6,
                title: 'Subload more 3',
                children: [
                    {
                        id: 7,
                        title: 'Subload more 4',
                        children: []
                    }
                ]
            }
        ]
    },
    {
        id: 8,
        title: 'Load more, empty from beginning',
        totalCount: 120,
        children: []
    },
];

@Component({
    selector: 'ui-list-docs-preview',
    templateUrl: './list.docs.preview.html',
    styleUrls: ['./list.docs.preview.scss'],
})
export class UIListDocsPreview {

    @ViewChild('exampleList') exampleList: UIListComponent;
    public showGridView = false;

    public dataSource: UIListDataSource;
    public expandableCheckboxDataSource: UIListDataSource;
    public multiSelectDataSource: UIListDataSource;

    private modifyData = {
        id: 3,
        title: 'This can be modified',
        disabled: false
    };

    private modifyCount = 0;

    constructor() {

        const data: any[] = [...TREE_DATA, this.modifyData];

        this.dataSource = new UIListDataSource(data);
        this.expandableCheckboxDataSource = new UIListDataSource(expandableCheckboxData);
        this.dataSource.filterBy = ['title', 'id']; // An optional parameter, if you want to just include these when searching.
        console.log('dataSource', this.dataSource.data);

        this.multiSelectDataSource = new UIListDataSource(PHOTO_DATA.slice(0, 5));
        console.log('multiSelectDataSource', this.multiSelectDataSource.data);

    }

    public ngAfterViewInit() {
        // setTimeout(() => {
        //     this.dataSource.data.forEach((item: any) => {
        //         this.exampleList.select(item);
        //     });
        // });
    }

    onLoadMore(node?: IUIListDataNode) {
        console.log('onLoadMore', node);
        setTimeout(() => {
            this.dataSource.insert(JSON.parse(JSON.stringify(LOAD_MORE)), node);
        }, 2000);

    }

    onDrop(event: UIListDropEvent) {
        console.log('preview.OnDrop', event);
        this.dataSource.move(event.nodes, event.to);
    }

    addNewItem(addToTop: boolean) {
        const item = {
            title: 'New Item',
            children: [
                {
                    id: 998,
                    title: 'child one',
                    disabled: true,
                },
                {
                    id: 999,
                    title: 'child two',
                }
            ]
        };

        if (addToTop) {
            this.dataSource.insert(item, undefined, true, true);
            this.expandableCheckboxDataSource.insert(item, undefined, true, true);

        }
        else {
            this.dataSource.insert(item, undefined, true, false);
            this.expandableCheckboxDataSource.insert(item, undefined, true, false);
        }

        this.exampleList.edit(item);
    }

    modifyItem() {
        this.modifyCount++;

        this.dataSource.update(this.modifyData, {
            title: `Modified ${this.modifyCount} times`
        });
    }

    onSelect(selection: any[]) { }

    onEdit(data: IUIEditedCellData) {
        console.log(data);
    }

    onBackDrop(event: UIListDropEvent): any {
        console.log('backdrop', event);
    }

    goToParent(): void {
        this.exampleList.goToParent();
    }
}


